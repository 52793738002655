const colorPickerOptions = {
    Card: {
        'card-background': 'Card Background',
        'card-header-background': 'Card Header Background',
        'card-footer-background': 'Card Footer Background',
        'card-header-color': 'Card Header Text',
        'card-footer-color': 'Card Footer Text',
        'card-color': 'Card Text'
    },
    Buttons: {
        'button-primary-bg': 'Primary Button Background',
        'button-primary-color': 'Primary Button Text',
        'button-secondary-bg': 'Secondary Button Background',
        'button-secondary-color': 'Secondary Button Text'
    },
    General: {
        'headings-color': 'Heading Text',
        'subheadings-color': 'Subheading Text',
        'body-color': 'Body Text'
    },
    Nav: {
        'nav-color': 'Navigation Text',
        'nav-header-color': 'Navigation Header Text',
        // 'nav-dropdown-bg': 'Navigation Dropdown Background'
    },
    Footer: {
        'footer-background': 'Footer Background'
    }
    // Header: {

    // }
}

class PreviewWidget {
    init() {
        this.view = new Vue({
            el: '#preview-widget',
            name: 'Preview Widget',

            data() {
                return {
                    colorPickerOptions: colorPickerOptions,

                    colorPickerSelections: Object.keys(colorPickerOptions).reduce((acc, category) => {
                        const optKeys = Object.keys(colorPickerOptions[category])
                        if (optKeys.length) {
                            optKeys.forEach(key => acc[key] = '')
                        }
                        return acc
                    }, {}),

                    timer: null,

                    get colorPickerOptionsList() {
                        return Object.entries(this.colorPickerOptions)
                    },

                    get colorPickerSelection() {
                        return identifier => this.colorPickerSelections[identifier]
                    }
                };
            },

            mounted() {
                const rootStyle = getComputedStyle(document.documentElement)
                Object.keys(this.colorPickerSelections).forEach(identifier => {
                    let propValue = rootStyle.getPropertyValue(`--brand-${identifier}`).replace(/\s+/g, '');
                    // If the value hasn't been set then pull from read-only defaults.
                    if (!propValue) {
                        propValue = rootStyle.getPropertyValue(`--read-only-brand-${identifier}`).replace(/\s+/g, '');
                    }
                    if (propValue) {
                        let hexColor = propValue

                        // Convert back from rgb to hex value
                        if (identifier === 'card-header-background' && propValue.indexOf('#') === -1) {
                            const rgbParts = propValue.split(',').map(part => part.trim())
                            hexColor = this.rgbToHex(rgbParts[0], rgbParts[1], rgbParts[2])
                        }

                        if (hexColor.length === 4) {
                            const hexHalf = propValue.split('#').pop()
                            hexColor = `#${hexHalf}${hexHalf}`
                        }
                        this.colorPickerSelections[identifier] = hexColor
                    }
                })

                window.addEventListener('message', event => {
                    if (event.origin !== event.data.origin) {
                        return
                    }

                    if (event.data.action === 'set-preview') {
                        window.opener.postMessage({
                            action: 'resetInterval'
                        }, '*')
                    }

                    if (event.data.result) {
                        ShowNotification.success(event.data.result)
                    }
                })

                const debouncedPaddingUpdate = this.debounce(() => {
                    document.body.style.paddingBottom = `${this.$el.offsetHeight}px`
                })

                window.addEventListener('resize', debouncedPaddingUpdate)
                $('#previewCollapse').on('shown.bs.collapse hidden.bs.collapse', debouncedPaddingUpdate)

                document.body.style.paddingBottom = `${this.$el.offsetHeight}px`
            },

            methods: {
                debounce(func, timeout = 300) {
                    let timer;
                    return (...args) => {
                        clearTimeout(timer);
                        timer = setTimeout(() => { func.apply(this, args); }, timeout);
                    };
                },

                hexToRgb(hex) {
                    // Expand shorthand form (e.g. "03F") to full form (e.g. "0033FF")
                    const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
                    hex = hex.replace(shorthandRegex, (m, r, g, b) => r + r + g + g + b + b);

                    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
                    return result ? {
                        r: parseInt(result[1], 16),
                        g: parseInt(result[2], 16),
                        b: parseInt(result[3], 16)
                    } : null;
                },

                componentToHex(c) {
                    const hex = c.toString(16);
                    return hex.length == 1 ? "0" + hex : hex;
                },

                rgbToHex(r, g, b) {
                    return "#" + this.componentToHex(parseInt(r, 10)) + this.componentToHex(parseInt(g, 10)) + this.componentToHex(parseInt(b, 10));
                },

                updateVariable(event, variableIdentifier) {
                    const root = document.documentElement
                    let value = event.target.value
                    let modValue

                    if (variableIdentifier === 'card-header-background') {
                        const result = this.hexToRgb(value)
                        if (result) {
                            modValue = `${result.r}, ${result.g}, ${result.b}`
                        }
                    }

                    this.colorPickerSelections[variableIdentifier] = value
                    root.style.setProperty(`--brand-${variableIdentifier}`, modValue ? modValue : value)
                },

                saveBrandColors() {
                    const colorSelections = { ...this.colorPickerSelections }
                    const result = this.hexToRgb(colorSelections['card-header-background'])
                    if (result) {
                        colorSelections['card-header-background'] = `${result.r}, ${result.g}, ${result.b}`
                    }

                    window.opener.postMessage({
                        action: 'brandColors',
                        colors: colorSelections
                    }, '*')
                },
                resetBrandColors() {
                    window.opener.postMessage({
                        action: 'resetBrandColors',
                        colors: null
                    }, '*')
                }
            }
        })
    }
}